import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter, RouterProvider, createBrowserRouter } from "react-router-dom";
// Function to create a BrowserRouter based on provided router configuration
export const buildAppRoute = (router) => createBrowserRouter(router);
// Functional component representing the application router
export const AppRouter = ({ router }) => {
    const _router = buildAppRoute(router); // Create BrowserRouter instance based on provided router
    return _jsx(RouterProvider, { router: _router }); // Render RouterProvider with created BrowserRouter
};
export const AppBrowserRouter = ({ children }) => {
    return _jsx(BrowserRouter, { children: children });
};
