import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
export const createMobxContext = (initialData) => {
    const RootStoreContext = createContext(null);
    const useStore = () => {
        const store = useContext(RootStoreContext);
        if (store === null) {
            throw new Error("Store cannot be null, please add a context provider");
        }
        return store;
    };
    const MobxProvider = ({ children }) => {
        return (_jsx(RootStoreContext.Provider, { value: initialData, children: children }));
    };
    return {
        getUseStore: () => useStore,
        MobxProvider,
    };
};
