import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Text from "./Text";
import Flex from "./Flex";
import { Colors } from "../colors";
const useStyles = makeStyles({
    root: {
        width: "100%",
        borderRadius: 10,
        display: "flex",
        minHeight: 500,
        overflow: "auto",
        background: "transparent",
    },
    container: {
        borderRadius: 10,
        minHeight: 0,
        overflow: "auto",
    },
    tableHeaderRow: {
        background: "transparent",
        borderBottom: 'solid 1px',
        borderColor: Colors.appBorderColor
    },
    tableRow: {
        backgroundColor: "transparent",
        "&:nth-child(odd)": {
            background: "transparent",
        },
    },
});
export function MuiTableWrapper(props) {
    const classes = useStyles();
    const { data, columns, pagination, loading, minWidth, minHeight, loadingIcon } = props;
    return (_jsx(Paper, { className: classes.root, children: _jsx(TableContainer, { className: classes.container, style: {
                width: "100%", position: "relative", paddingBottom: pagination ? 86 : 16
            }, children: _jsxs(Table, { sx: { minWidth: minWidth, minHeight: minHeight }, "aria-label": "sticky table", stickyHeader: true, children: [_jsx(TableHead, { style: {
                            backgroundColor: Colors.tableHeaderBg,
                        }, children: _jsx(TableRow, { className: classes.tableHeaderRow, children: columns.map((column, index) => (_jsx(TableCell, { style: {
                                    backgroundColor: Colors.tableHeaderBg,
                                    borderBottom: 'solid 1px',
                                    borderColor: Colors.appBorderColor
                                }, children: typeof column.label === "string" ? (_jsx(Text, { color: "#86889A", variant: "bold", children: column.label })) : (column?.label?.()) }, index))) }) }), _jsx(TableBody, { style: { height: "100%" }, children: loading ?
                            _jsx(TableRow, { children: _jsx(TableCell, { colSpan: columns.length, children: _jsx(Flex, { center: true, fullSize: true, minHeight: 600, children: loadingIcon ? loadingIcon : "" }) }) })
                            : data.map((row, rowIndex) => {
                                return (_jsx(TableRow, { role: "checkbox", tabIndex: -1, className: classes.tableRow, children: columns.map((column, columnIndex) => {
                                        const value = row[column.dataKey];
                                        return (_jsx(TableCell, { children: column.cellRenderer(value, row) }, columnIndex));
                                    }) }, rowIndex));
                            }) })] }) }) }));
}
export default MuiTableWrapper;
