import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MuiContext } from "../context";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from 'notistack';
import { NotiStack, ConfirmModal, AppModal } from "../components";
import { createAppTheme } from "./../theme";
const MuiContextWrapper = ({ children }) => {
    return _jsx(MuiContext.Provider, { children: children });
};
export const defaultTheme = createAppTheme({});
// eslint-disable-next-line no-var
var _ConfirmModalInstance = {};
var _AppModalInstance = {};
export const ConfirmModalInstance = {
    addMessage: (message) => {
        _ConfirmModalInstance && _ConfirmModalInstance.addMessage(message);
    },
};
export const AppModalInstance = {
    addModal: (props) => {
        _AppModalInstance && _AppModalInstance.addModal(props);
    },
    closeModal: (keyModal) => {
        _AppModalInstance && _AppModalInstance.closeModal(keyModal);
    },
};
var _NotiStackInstance = {};
export const NotiStackInstance = {
    push: (message) => {
        _NotiStackInstance &&
            _NotiStackInstance.push &&
            _NotiStackInstance.push(message);
    },
};
export const MuiProvider = ({ children, }) => {
    const { themeMapping, themeKey } = MuiContext.useDataContext() || {};
    return (_jsx(MuiContextWrapper, { children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsxs(ThemeProvider, { theme: {
                        ...defaultTheme,
                        ...(themeKey && themeMapping?.[themeKey] ? themeMapping[themeKey] : {})
                    }, children: [_jsx(SnackbarProvider, { anchorOrigin: {
                                horizontal: 'left',
                                vertical: 'top'
                            } }), _jsx(CssBaseline, {}), children, _jsx(ConfirmModal, { ref: (ref) => {
                                _ConfirmModalInstance = ref;
                            } }), _jsx(AppModal, { ref: (ref) => {
                                _AppModalInstance = ref;
                            } }), _jsx(NotiStack, { ref: (ref) => {
                                _NotiStackInstance = ref;
                            } })] }) }) }) }));
};
