import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import Flex from "../Flex";
import { TableRow, TableCell, Paper } from "@mui/material";
import { Fade, Menu, MenuItem } from "@mui/material";
import Text from "../Text";
import { Colors } from "../../colors";
import { observer } from "@core-ui/react-mobx-state";
export function TableHeaderRow(props) {
    const classes = useStyles();
    const { columns, filter, setFilter, state } = props;
    return (_jsx(TableRow, { className: classes.tableHeaderRow, children: columns.map((column, index) => (_jsxs(TableCell, { style: {
                minWidth: column.minWidth || 0,
                padding: "16px",
            }, className: column.switchable
                ? classes.headerCellSwitchable
                : classes.headerCell, children: [_jsxs(Flex, { fullWidth: true, fullHeight: true, position: "relative", justifyContent: column.switchable
                        ? "center"
                        : column.right
                            ? "flex-end"
                            : "flex-start", centerY: true, onClick: (event) => {
                        if (column.sort) {
                            state.anchorEl = event.currentTarget;
                            state.currentSortColumn = column.dataKey;
                        }
                    }, className: column.switchable ? classes.columnSwitchable : "", children: [_jsx(Text, { className: classes.headerText, whiteSpace: "nowrap", children: typeof column.label === "function"
                                ? column.label(state.switchIndex)
                                : column.label }), column.sort && (_jsx(Flex, { position: "relative" }))] }), column.sortOptions && (_jsx(Menu, { open: state.currentSortColumn === column.dataKey, onClose: () => {
                        state.anchorEl = null;
                        state.currentSortColumn = "";
                    }, keepMounted: true, anchorEl: state.anchorEl, TransitionComponent: Fade, defaultValue: filter.sort?.value, children: _jsx(Paper, { className: classes.sortMenu, children: column.sortOptions?.map((opt) => (_jsx(MenuItem, { onClick: () => {
                                setFilter &&
                                    setFilter((prev) => ({
                                        ...prev,
                                        sort: {
                                            value: opt.value,
                                            column: column.dataKey,
                                            direct: opt.direct,
                                        },
                                    }));
                                state.currentSortColumn = "";
                                state.anchorEl = null;
                            }, className: classes.sortItem, value: opt.value, children: _jsxs(Flex, { fullWidth: true, justifyContent: "space-between", children: [_jsx(Text, { children: opt.name }), "\u00A0", _jsx(Text, { children: opt.sort })] }) }, opt.value))) }) }))] }, index))) }));
}
const useStyles = makeStyles({
    tableHeaderRow: {},
    headerCell: {
        background: `${Colors.gray200} !important`,
        "&:hover": {
            color: `${Colors.gray200} !important`,
        },
    },
    headerCellSwitchable: {
        background: `${Colors.gray200} !important`,
    },
    columnSwitchable: {
        paddingLeft: "20px",
        paddingRight: "20px",
        textAlign: "center",
        justifyContent: "center",
    },
    switchableLeft: {
        position: "absolute",
        left: "2px",
        cursor: "pointer",
        "&:hover": {
            color: `${Colors.gray200} !important`,
        },
    },
    switchableRight: {
        position: "absolute",
        right: "2px",
        cursor: "pointer",
        "&:hover": {
            color: `${Colors.gray200} !important`,
        },
    },
    sortMenu: {
        background: `${Colors.gray200} !important`,
        border: Colors.gray200,
        boxShadow: `${Colors.gray200} !important`,
    },
    sortItem: {
        color: `${Colors.gray200} !important`,
        background: `transparent !important`,
        "&:hover": {
            background: `${Colors.gray200} !important`,
        },
    },
    headerText: {
        cursor: "pointer",
    },
});
export default observer(TableHeaderRow);
