import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
export const useResponsive = (customSize = {}) => {
    const theme = useTheme();
    // const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
    const xsUp = useMediaQuery(theme.breakpoints.up('xs'));
    const smUp = useMediaQuery(theme.breakpoints.up('sm'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
    let handledCustomSize = {};
    if (customSize) {
        Object.keys(customSize).forEach((key) => {
            handledCustomSize[key] = useMediaQuery(theme.breakpoints.down(customSize[key]));
        });
    }
    return {
        minSizeUp: xsUp,
        phoneSizeUp: smUp,
        tabletSizeUp: mdUp,
        webviewSizeUp: lgUp,
        maxSizeUp: xlUp,
        phoneSizeDown: smDown,
        tabletSizeDown: mdDown,
        webviewSizeDown: lgDown,
        maxSizeDown: xlDown,
        theme,
        ...handledCustomSize,
    };
};
export default useResponsive;
