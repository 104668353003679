import { makeContext } from "@core-ui/react-context";
export var MuiActionType;
(function (MuiActionType) {
    MuiActionType["INIT_STATE"] = "init_state";
    MuiActionType["UPDATE_THEME"] = "update_theme";
    MuiActionType["ADD_THEME"] = "add_theme";
})(MuiActionType || (MuiActionType = {}));
const reducer = (state, action) => {
    switch (action.type) {
        case MuiActionType.ADD_THEME:
            const newMapping = state.themeMapping || {};
            if (action.payload.newKey && action.payload.newTheme) {
                newMapping[action.payload.newKey] = action.payload.newTheme;
            }
            return {
                ...state,
                themeMapping: newMapping
            };
        case MuiActionType.UPDATE_THEME:
            return {
                ...state,
                themeKey: action.payload.themeKey
            };
        case MuiActionType.INIT_STATE:
            return action.payload;
        default:
            return state;
    }
};
export const MuiContext = makeContext(reducer, {}, "Mui");
