import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from "react";
import { useReducerWithLogger } from "../hooks";
export const makeContext = (reducer, initialData, contextName) => {
    const DataContext = createContext(null);
    const DataDispatchContext = createContext(null);
    const ContextProvider = ({ children, value }) => {
        const [data, dispatch] = useReducerWithLogger(reducer, initialData, contextName);
        useEffect(() => {
            if (value) {
                dispatch({
                    type: "init_state",
                    payload: { ...value }
                });
            }
        }, [value]);
        return (_jsx(DataContext.Provider, { value: data, children: _jsx(DataDispatchContext.Provider, { value: dispatch, children: children }) }));
    };
    const useDataContext = () => useContext(DataContext);
    const useDataDispatchContext = () => useContext(DataDispatchContext);
    return {
        Provider: ContextProvider,
        useDataContext,
        useDataDispatchContext
    };
};
