import { jsx as _jsx } from "react/jsx-runtime";
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { forwardRef, Fragment, useImperativeHandle } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from "../hooks/useResponsive";
const _NotiStack = (_props, ref) => {
    const { tabletSizeDown } = useResponsive();
    useImperativeHandle(ref, () => ({
        push: (msg) => {
            const action = (key) => (_jsx(IconButton, { "aria-label": "close", color: "inherit", onClick: () => closeSnackbar(key), children: _jsx(CloseIcon, {}) }));
            enqueueSnackbar({
                message: (_jsx("span", { style: {
                        maxWidth: tabletSizeDown ? 300 : 500,
                        wordWrap: 'break-word',
                        whiteSpace: 'break-spaces',
                        height: 'fit-content'
                    }, children: msg.children })),
                variant: msg.variant,
                autoHideDuration: msg.timeout || 2800,
                preventDuplicate: true,
                action,
                TransitionProps: {
                    direction: "down",
                },
            });
        },
    }));
    return (_jsx(Fragment, {}));
};
export const NotiStack = forwardRef(_NotiStack);
export default forwardRef(_NotiStack);
