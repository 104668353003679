import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import Flex from "./Flex";
export const Loading = (props) => {
    const styles = useStyles(props);
    return (_jsx(Flex, { center: true, fullSize: true, minHeight: 22, style: props.style || {}, children: _jsx(Flex, { className: styles.root, center: true, children: _jsx(CircularProgress, { className: styles.bottom, size: props.size || 40, thickness: 5, 
                // color={props.color || "primary"}
                style: {
                    color: props.color || "#305AE8"
                }, ...props }) }) }));
};
const useStyles = makeStyles((_theme) => createStyles({
    root: {
        position: "relative",
    },
    bottom: {
        color: "#305AE8",
    },
    top: {
        color: "#305AE8",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
    },
    circle: {
        strokeLinecap: "round",
    },
}));
export default Loading;
