export const Colors = {
  green: "#35e984",
  red: "#f97066",
  blue: "#55E0FF",
  yellow: "#E4C65B",
  nearWhite: "#FFFEFA",
  nearWhite2: "#FAFAFA",
  nearWhite3: "#F0F0F0",
  appContentBgColor: "#101010",
  appContentBgColor2: "#262424",
  appBorderColor: "#3a3636",
  appBorderActiveColor: "#5e7eed",
  appActiveBgColor: "#20262f",
  appBgFixedFooter: "#1f2a37",
  appHoverBackground: "linear-gradient(90deg, #0d2473 0%, #000000 100%)",
  appModalGradientBgColor: "linear-gradient(90deg, #663f00 0%, #000000 100%)",
  winnerBoxShadow: "inset 0px 0px 16px #f4a525",
  appColorBlack: "#000000",
  appColorBlue: "#305ae8",
  appBgOrange: "#422900",
  appBorderInactive: "#595959",
  appBgActive: "#001149",
  appColorGreen2: "#98dd27",
  textWhite: "#ffffff",
  textBlack: "#000000",
  textBlack2: "#3a3636",
  textActive: "#ffc870",
  textOrange: "#f4a525",
  textOrange2: "#ffbb4d",
  textGray: "#a0a0a0",
  textGray2: "#8c8c8c",
  textGray4: "#bfbfbf",
  textGray5: "#595959",
  textGray6: "#cfcfcf",
  textRed: "#e25c5c",
  gradientBgColor:
    "linear-gradient(90deg, rgba(248, 204, 50, 0.5) 0%, rgba(17, 25, 39, 0.2) 65%, rgba(17, 25, 39, 0.2) 100%)",
  gradientTopNotificationBg:
    "linear-gradient(90.03deg, #5e7eed -5.8%, #102e93 124.9%)",
  dangerColor: "#ED4E1C", //red
  warning: "#FF8A00",
  primary2: "#262424",
  gray: "#BFBFBF",
  darkBlock: "#262424",
  black: "#000000",
  white: "#ffffff",
  modalBg: "#222121",
  primary: "#000000",
  tableHeaderBg: "#03060c",
  link: "#3493FE",
  border: "#CFCFCF",
  rowHoverBg: "#1D3647",
  gray200: "#E7E7E7",
  textColor: "#262424",
  disabled: "#AAAAAA",
  secondary: "#FFFFFF",
  block: "rgba(255, 255, 255, 0.05)",
  setColors: [
    "#F74367",
    "#FFBB4D",
    "#FA00FF",
    "#7A43FF",
    "#305AE8",
    "#2600C0",
    "#670098",
    "#00BB88",
    "#FCFC03",
    "#BA4F00",
    "#885EED",
    "#2EFFC0",
    "#0039FF",
    "#2EFF5C",
    "#FFBB4D",
    "#5E7EED",
    "#FF6B00",
    "#F91E1E",
    "#FF7D7D",
    "#006717",
    "#FF965B",
    "#00C22B",
    "#BDACFF",
    "#FFF48F",
    "#8A0400",
    "#B5FFE4",
    "#FD99FF",
    "#8B96FF",
    "#FF92A8",
  ],
};
