import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import Flex from "../Flex";
import { TableCell, TableRow } from "@mui/material";
import { Colors } from "../../colors";
export function TableRowComponent(props) {
    const classes = useStyles();
    const { columns, rowClick, rowIndex, row, switchIndex, } = props;
    return (_jsx(TableRow, { role: "checkbox", tabIndex: -1, onClick: (() => rowClick && rowClick(row)) || (() => { }), className: classes.tableRow, children: columns.map((column, columnIndex) => {
            const value = row[column.dataKey];
            if (column.switchable) {
                return (_jsx(TableCell, { style: {
                        minWidth: column.minWidth || 0,
                    }, children: column.cellRenderer(value, row, switchIndex) }, columnIndex));
            }
            return (_jsx(TableCell, { children: _jsx(Flex, { children: column.cellRenderer(value, row) }) }, columnIndex));
        }) }, rowIndex));
}
const useStyles = makeStyles({
    tableRow: {
        borderTop: `${Colors.border}`,
        cursor: "pointer",
        "&:hover": {},
    },
});
export default TableRowComponent;
