import { Layers } from "@/styles/layers"
import { Flex, Loading } from "@core-ui/react-mui-core"
import logoFull from "@/assets/images/logo_full.png"

export const LoadingPage = () => {

  return <Flex position={"fixed"} width={"100vw"} height={"100vh"} zIndex={Layers.layerMax}
    center
    column
    bgcolor={"#000"}
    p={2}
  >
    <img src={logoFull} style={{ height: 40, width: 240 }} />
    <Loading size={24} />
  </Flex>
}