import { jsx as _jsx } from "react/jsx-runtime";
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from "@mui/material";
import { observer } from "@core-ui/react-mobx-state";
import useResponsive from "../hooks/useResponsive";
import { Colors } from '../colors';
export const ReloadBtn = (props) => {
    const tabletSizeDown = useResponsive();
    if (!tabletSizeDown && !props.forcedShow) {
        return null;
    }
    return _jsx(IconButton, { onClick: props.onClick, style: { borderRadius: 25, backgroundColor: Colors.white }, children: _jsx(RefreshIcon, { style: { fill: "#305AE8" } }) });
};
export default observer(ReloadBtn);
