import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { Colors } from "../colors";
const defaultColors = [
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
];
export const Text = (props) => {
    const { color = Colors.white, style: styleProps = {}, textAlign, lineHeight, ...rest } = props;
    const theme = useTheme();
    //@ts-ignore
    const isDefaultColors = defaultColors.includes(color);
    const colorProps = isDefaultColors
        ? {
            color: color,
        }
        : undefined;
    const style = {
        ...styleProps,
        //@ts-ignore
        color: !isDefaultColors ? theme.colors[color] || color : undefined,
        textAlign: textAlign || styleProps.textAlign,
    };
    const boxProps = {
        ...rest,
        textAlign: undefined,
    };
    const typoProps = {
        ...rest,
        maxWidth: undefined,
        onClick: undefined,
    };
    //@ts-ignore
    return (_jsx(Box, { style: style, ...boxProps, children: _jsx(Typography, { component: "div", style: {
                lineHeight: lineHeight || "1.75em",
                ...style
            }, ...colorProps, ...typoProps }) }));
};
export default Text;
