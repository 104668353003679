import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
// import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { Fragment, forwardRef, useImperativeHandle, useState, } from "react";
import Flex from "./Flex";
import Text from "./Text";
import { Colors } from '../colors';
import useResponsive from '../hooks/useResponsive';
const DialogContent = withStyles((_theme) => ({
    root: {
        borderTop: "none",
        borderBottom: "none",
    },
}))(MuiDialogContent);
const _ConfirmModal = (props, ref) => {
    const { tabletSizeDown } = useResponsive();
    const styles = useStyles(props);
    // const state = useLocalStore(() => ({
    //   isLoading: false,
    // }));
    const [messages, setMessages] = useState([]);
    useImperativeHandle(ref, () => ({
        addMessage: (msg) => {
            setMessages((messages) => {
                return [...messages, msg];
            });
        },
    }));
    const onDismiss = (index) => {
        // eslint-disable-next-line prefer-const
        let newMessages = [...messages];
        newMessages.splice(index, 1);
        setMessages((messages) => {
            const newMessages = [...messages];
            newMessages.splice(index, 1);
            return newMessages;
        });
    };
    if (messages.length === 0) {
        return _jsx("div", {});
    }
    // const onActionbtnClicked = async (
    //   index: number,
    //   msg: ConfirmModalMessage
    // ) => {
    //   const action = msg.action ? msg.action : () => { };
    //   state.isLoading = true;
    //   try {
    //     await action();
    //     onDismiss(index);
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   state.isLoading = false;
    // };
    return (_jsx(Fragment, { children: messages.map((item, index) => {
            let title = item.title ? item.title : "INFO";
            const { isAlertMessageOnly, cancelText, isSuccessErrorAlert, } = item;
            return (_jsx(Dialog, { onClose: () => {
                    onDismiss(index);
                }, "aria-labelledby": "customized-dialog-title", open: true, fullWidth: true, maxWidth: "sm", classes: {
                    root: tabletSizeDown ? styles.rootMobile : styles.root,
                    paper: styles.paper,
                }, transitionDuration: {
                    appear: 500,
                    enter: 200,
                    exit: 300,
                }, children: _jsxs(DialogContent, { style: {
                        background: Colors.modalBg,
                    }, classes: {
                        root: tabletSizeDown ? styles.dialogContentMobile : undefined,
                    }, children: [_jsx(Flex, { position: "absolute", top: 10, right: 10, cursorPointer: true, onClick: () => {
                                onDismiss(index);
                            }, children: _jsx(ClearIcon, { style: { color: "whitesmoke" } }) }), _jsxs(Flex, { column: true, justifyContent: "space-between", centerY: true, minHeight: 200, px: 1, children: [_jsx(Flex, { fullWidth: true, center: true, py: 1, children: _jsx(Text, { variant: "bold", color: "#F7F7F7", style: { fontSize: 25 }, children: title }) }), typeof item.children === "string" ? (_jsx(Text, { style: { fontSize: 18 }, color: isSuccessErrorAlert !== undefined
                                        ? isSuccessErrorAlert === "SUCCESS"
                                            ? "success"
                                            : "error"
                                        : undefined, textAlign: "center", children: item.children })) : (item.children()), _jsx(Flex, { centerY: true, fullWidth: true, py: 3, children: isAlertMessageOnly ? null : (_jsx(Button, { fullWidth: true, variant: "blackOutline", onClick: () => {
                                            onDismiss(index);
                                        }, style: { margin: "0 5px", border: `1px solid ${Colors.white}` }, children: _jsx(Text, { variant: 'bold', color: 'white', children: cancelText || "NO" }) })) })] })] }) }, index));
        }) }));
};
const useStyles = makeStyles((theme) => createStyles({
    closeButton: {
        position: "absolute",
        right: -5,
        top: -5,
        color: theme.palette.grey[500],
    },
    root: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
        padding: '0 16px'
    },
    rootMobile: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
        padding: '0px'
    },
    dialogContentMobile: {
        padding: '10px'
    },
    paper: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
    },
}));
export const ConfirmModal = forwardRef(_ConfirmModal);
export default forwardRef(_ConfirmModal);
