import { MOBILE_SIZE } from "../utils";

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600, // phone size
    md: 900, // tablet size
    lg: MOBILE_SIZE, // desktop size
    xl: 1440, // max width size
  },
}