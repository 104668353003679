import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import Flex from "./Flex";
import { useLocalStore, observer } from "@core-ui/react-mobx-state";
import { Layers } from "./../constants";
import { useResponsive } from "../hooks";
const DialogContent = withStyles((_theme) => ({
    root: {
        borderTop: "none",
        borderBottom: "none",
        zIndex: Layers.modalLayer
    },
}))(MuiDialogContent);
const _AppModal = (_props, ref) => {
    const state = useLocalStore(() => ({
        modalsProps: {},
        index: 0
    }));
    useImperativeHandle(ref, () => ({
        addModal: (_props) => {
            if (!_props.id)
                state.index++;
            const index = state.index;
            state.modalsProps[_props.id || index] = {
                ..._props,
                index,
                onClose: () => {
                    delete state.modalsProps[index];
                }
            };
        },
        closeModal: (keyModal) => {
            delete state.modalsProps[keyModal];
        },
    }));
    const keys = Object.keys(state.modalsProps);
    return _jsx("div", { children: keys.map((key) => {
            return _jsx(ModalItem, { ...state.modalsProps[key], keyModal: key }, key);
        }) });
};
const ModalItem = (props) => {
    const styles = useStyles(props);
    const { childrenComponent, modalProps, closeCallback, onClose, keyModal, disabledBackdrop, disableCloseable } = props;
    const { tabletSizeDown } = useResponsive();
    return (_jsx(Dialog, { onClose: (_e, reason) => {
            if (disabledBackdrop && reason === "backdropClick") {
                return false;
            }
            else {
                onClose();
                closeCallback && closeCallback();
                return true;
            }
        }, "aria-labelledby": "customized-dialog-title", open: true, fullWidth: true, maxWidth: "xl", transitionDuration: {
            appear: 500,
            enter: 200,
            exit: 300,
        }, classes: {
            root: tabletSizeDown ? styles.rootMobile : styles.root,
            paper: styles.paper,
        }, ...modalProps, children: _jsxs(DialogContent, { style: {
                padding: modalProps.noPadding ? "0px" : '16px'
            }, classes: {
                root: styles.dialogContent
            }, children: [!disableCloseable && (_jsx(Flex, { position: "absolute", top: 10, right: 24, pt: 1, cursorPointer: true, onClick: () => {
                        onClose();
                        closeCallback && closeCallback();
                    }, children: _jsx(ClearIcon, { color: modalProps.closeIconColor || "white" }) })), childrenComponent && childrenComponent(keyModal, modalProps || {}, onClose)] }) }));
};
const useStyles = makeStyles((_theme) => createStyles({
    root: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
        padding: '0px',
        zIndex: Layers.modalLayer,
    },
    rootMobile: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
        padding: '0px',
        zIndex: Layers.modalLayer,
    },
    dialogContent: {
        padding: '0px',
        overflowX: 'hidden',
    },
    paper: {
        boxShadow: "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
    },
}));
export const AppModal = observer(forwardRef(_AppModal));
export default observer(forwardRef(_AppModal));
