import { useEffect, useReducer } from "react";
export const useReducerWithLogger = (reducer, initialData, contextName) => {
    const [state, dispatch] = useReducer(reducer, initialData);
    useEffect(() => {
        //Add more middle ware here...
        if (process.env.NODE_ENV === "development") {
            console.info('Next state', contextName, state);
        }
    }, [state]);
    return [state, dispatch];
};
