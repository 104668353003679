import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import useTheme from "@mui/styles/useTheme";
const defaultColors = [
    "primary.main",
    "primary.light",
    "primary.dark",
    "secondary.main",
    "secondary.main",
    "secondary.light",
    "error.light",
    "error.dark",
    "error.dark",
    "success.light",
    "success.dark",
    "success.dark",
    "info.light",
    "info.dark",
    "info.dark",
    "background.light",
    "background.dark",
    "background.dark",
];
export const Flex = (props) => {
    const theme = useTheme();
    const { bgcolor, style: styleProps = {}, column, center, centerX, centerY, children, fullWidth, fullHeight, fullSize, ...rest } = props;
    let centerProps = {};
    if (center) {
        centerProps["justifyContent"] = "center";
        centerProps["alignItems"] = "center";
    }
    if (centerX) {
        centerProps["justifyContent"] = "center";
    }
    if (centerY) {
        centerProps["alignItems"] = "center";
    }
    if (column) {
        centerProps["flexDirection"] = "column";
    }
    else {
        centerProps["flexDirection"] = "row";
    }
    let sizeProps = {};
    if (fullWidth) {
        sizeProps.width = "100%";
    }
    if (fullHeight) {
        sizeProps.height = "100%";
    }
    if (fullSize) {
        sizeProps.height = "100%";
        sizeProps.width = "100%";
    }
    //@ts-ignore
    const isDefaultColors = defaultColors.includes(bgcolor);
    const colorProps = isDefaultColors
        ? {
            bgcolor: bgcolor,
        }
        : undefined;
    const style = {
        ...styleProps,
        //@ts-ignore
        backgroundColor: !isDefaultColors
            ? //@ts-ignore
                theme.colors[bgcolor] || bgcolor
            : undefined,
    };
    if (props.cursorPointer) {
        style.cursor = "pointer";
    }
    return (_jsx(Box, { display: "flex", style: style, ...sizeProps, ...centerProps, ...colorProps, ...rest, children: children }));
};
export default Flex;
