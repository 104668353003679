export const Layers = {
    layer1: 1,
    layer2: 10,
    layer3: 20,
    layer4: 30,
    layer5: 40,
    layer6: 50,
    layer7: 60,
    layer8: 70,
    layer9: 80,
    layer10: 90,
    layer11: 100,
    layer12: 110,
    layout: 99999,
    modalLayer: 999997,
    notificationLayer: 999998,
    layerMax: 999999,
};
